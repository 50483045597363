<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <img
          src="@/assets/images/ecosystem-partners.png?t=777"
          class="img-fluid"
          alt="Contact Us"
        />
        <br>
          <br>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 pt-5 mt-5 pb-3">

      <h2>Contact </h2>

        You can send us a message or you can write to us
        <a href="mailto:support@tamiltoken.org">
          <i class="fas fa-envelope"></i> Email
        </a>

        <br />
        We will get back to you in 2 to 3 business days.

        <br />
        <a href="https://t.me/tamiltoken">
          <i class="fab fa-telegram-plane"></i> Telegram
        </a>
        Stay updated with us <br />
        Follow us on our social media and be the first to know the latest news!

        <hr>

        <a href="/ecosystem-partners">
          Visit our ecosystem partners
        </a>

      </div>


    </div>
  </div>
</template>
